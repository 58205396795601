'use client';

import { Form } from 'antd';
import React, { useState } from 'react';

import FloatSelect from '@/app/_components/float-select/FloatSelect';
import { VendorOffer } from '@/app/_types/components/vendor-offering';

import Styles from './ServicesSelector.module.scss';

type ServiceSelectorProps = {
  vendorOffering?: VendorOffer;
  onSelectedOptionsChange: (selectedOptions: {
    [key: string]: string[];
  }) => void;
};

const ServicesSelector = ({
  vendorOffering,
  onSelectedOptionsChange,
}: ServiceSelectorProps) => {
  const [selectValue, setSelectValue] = useState<{ [key: string]: string[] }>(
    {},
  );

  const handleOptionChange = (categoryType: string, value: string[]) => {
    const updatedSelectValue = { ...selectValue, [categoryType]: value };

    setSelectValue(updatedSelectValue);
    onSelectedOptionsChange(updatedSelectValue);
  };

  return (
    <div className={Styles.servicesSelectorWrapper}>
      <Form
        className={Styles.servicesForm}
        size="large"
        layout="vertical"
        onFinish={(data) => data}
      >
        {vendorOffering &&
          vendorOffering.map((vendor, index) => {
            const options =
              vendor.children?.map((child) => ({
                value: child.name ?? '',
                label: child.name ?? '',
              })) || [];

            return (
              <div key={index}>
                <Form.Item
                  name={`${vendor?.category?.category_type}`}
                  className="customInput"
                  hasFeedback
                >
                  <FloatSelect
                    label={vendor?.category?.category_type ?? ''}
                    name={vendor?.category?.category_type ?? ''}
                    mode="multiple"
                    allowClear
                    options={options}
                    onChange={(value) =>
                      handleOptionChange(
                        vendor?.category?.category_type ?? '',
                        [value],
                      )
                    }
                    value=""
                  />
                </Form.Item>
              </div>
            );
          })}
      </Form>
    </div>
  );
};

export default ServicesSelector;
