'use client';
import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import { CTABanner } from '@/app/_types/components/cta-banner';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './HelpCard.module.scss';

type HelpCardProps = CTABanner & {
  filesMeta?: FilesMeta;
};

const HelpCard = (props: HelpCardProps) => {
  const {
    pre_text,
    title,
    title_2,
    paragraph,
    call_to_action,
    image,
    filesMeta = {},
    contact_number,
    operating_hours,
  } = props;

  return (
    <div className={Styles.helpCardContainer}>
      <div className="container">
        <div className={Styles.helpCardContent}>
          <div className={Styles.leftContent}>
            <div className="headingElement">
              {pre_text && <h4 className="subTitle">{pre_text}</h4>}
              {title && (
                <h3 className="titleXl">
                  {title} {title_2 && <span className="strong">{title_2}</span>}
                </h3>
              )}
            </div>

            {paragraph && (
              <div
                className={cn(Styles.description, 'editorDescription')}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            )}

            {contact_number && (
              <div className={Styles.contactContainer}>
                <div className="headingElement">
                  {title && <h4 className="titleMd">{contact_number}</h4>}
                </div>

                {operating_hours && (
                  <div className={Styles.contactDescription}>
                    {operating_hours}
                  </div>
                )}
              </div>
            )}

            <ButtonCustom
              btnText={call_to_action?.text}
              className="btnPrimaryAlt"
              extraClass={Styles.ctaCardBtn}
              href={
                call_to_action?.pdf
                  ? getDirectusAssetURI(call_to_action?.pdf ?? '') || ''
                  : call_to_action?.url
              }
              target={
                call_to_action?.new_tab || call_to_action?.pdf
                  ? '_blank'
                  : '_self'
              }
            />
          </div>
          <div className={Styles.rightContent}>
            {image && (
              <Image
                src={getDirectusAssetURI(image) ?? ''}
                alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                width={860}
                height={560}
                className={Styles.cardImage}
                quality={90}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCard;
