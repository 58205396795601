'use client';
import React from 'react';

import ImageMarquee from '@/app/_components/image-marquee/ImageMarquee';
import PageContent from '@/app/_components/page-content/PageContent';
import { FilesMeta } from '@/app/_types/file';
import { OurCorporateResponsibilityType } from '@/app/_types/our-corporate-responsibility';

import CtaBanner from './CtaBanner';

type Props = OurCorporateResponsibilityType & {
  filesMeta?: FilesMeta;
};
const OurCorporateResponsibilityPage = (props: Props) => {
  const { header, content, image_scroller, filesMeta } = props;

  const transformedImageScroller = image_scroller?.map((item, index) => ({
    id: index,
    image: item.directus_files_id,
  }));

  return (
    <>
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {content &&
        content.map((val, index) => (
          <PageContent {...val.copy_component_id} key={index} />
        ))}
      {image_scroller && (
        <ImageMarquee
          slider={transformedImageScroller}
          filesMeta={filesMeta ?? {}}
        />
      )}
    </>
  );
};

export default OurCorporateResponsibilityPage;
