import cn from 'classnames';
import Image from 'next/image';
import React, { useState } from 'react';

import closeIcon from '@/app/_icons/close.svg';
import openIcon from '@/app/_icons/openIcon.svg';
import { AboutUsVariation2 } from '@/app/_types/about-us';

import Styles from './MobileDropdown.module.scss';

type Props = {
  leadership_pre_text: string;
  leadership_title: string;
  leadership: AboutUsVariation2['leadership'];
};
const MobileDropdown = ({ leadership }: Props) => {
  const [activeTab, setActiveTab] = useState<number | null>(0);
  const handleTabClick = (index: number) => {
    setActiveTab((prevTab) => (prevTab === index ? null : index));
  };

  return (
    <div className={Styles.dropdownContainer}>
      {leadership?.map((company, index) => (
        <div key={index}>
          <div
            className={cn(Styles.companyTitle, 'text-primary medium', {
              [Styles.active]: activeTab === index,
            })}
            onClick={() => handleTabClick(index)}
          >
            {company.leadership_tab_id.group_name}
            {company.leadership_tab_id.members && (
              <span className={Styles.toggleIcon}>
                {activeTab === index ? (
                  <Image
                    src={closeIcon}
                    alt="openIcon"
                    height={20}
                    width={20}
                  />
                ) : (
                  <Image
                    src={openIcon}
                    alt="close icon"
                    height={20}
                    width={20}
                  />
                )}
              </span>
            )}
          </div>
          {activeTab === index && company.leadership_tab_id.members && (
            <div className={Styles.employeeList}>
              {company.leadership_tab_id.members.map((employee, empIndex) => (
                <div
                  key={empIndex}
                  className={cn(Styles.employeeItem, 'headingElement')}
                >
                  <h4 className={cn(Styles.employeeName, 'titleSm medium ')}>
                    {employee.members_id.name}
                  </h4>
                  <p
                    className={cn(
                      Styles.employeeDesignation,
                      'text-primary medium',
                    )}
                  >
                    {employee.members_id.title}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileDropdown;
