'use client';
import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { FilesMeta } from '@/app/_types/file';
import { HomeVariation2 } from '@/app/_types/home';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type Props = HomeVariation2['header_component'] & {
  filesMeta: FilesMeta;
};
const CtaBanner = (props: Props) => {
  const {
    static_title,
    dynamic_titles,
    paragraph,
    cta_button,
    image,
    filesMeta,
  } = props;

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContainer}>
          <div className={Styles.bannerImageContainer}>
            <div className={Styles.bannerImageWrapper} style={zoomStyle}>
              {image && (
                <Image
                  src={getDirectusAssetURI(image) ?? ''}
                  alt={getAltProperty(filesMeta, image ?? '', 'Banner')}
                  fill
                  className={Styles.bannerImage}
                />
              )}
            </div>
          </div>
          <div className={Styles.ctaBannerContent}>
            <div className={Styles.leftContentSection}>
              <div className={Styles.ctaBannerHeadingContainer}>
                <div className="headingElement">
                  {static_title && (
                    <h1 className="titleXXl light">{static_title}</h1>
                  )}
                </div>
                <div className={Styles.offeringText}>
                  {dynamic_titles ? (
                    <Swiper
                      slidesPerView={1}
                      loop={true}
                      autoplay={{
                        delay: 3500,
                        disableOnInteraction: true,
                      }}
                      centeredSlides={false}
                      modules={[Autoplay]}
                      className="mySwiper"
                    >
                      {Array.isArray(dynamic_titles) &&
                        dynamic_titles.map((text, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="headingElement">
                                <h2 className="titleXXl">{text.option}</h2>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={Styles.rightContentSection}>
              {paragraph && (
                <div
                  className={cn (Styles.description, 'editorDescription')}
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                ></div>
              )}

              {cta_button && (
                <ButtonCustom href={cta_button.url} btnText={cta_button.text} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
