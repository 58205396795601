'use client';

import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { ProductCategoryHeader } from '@/app/_types/components/product-category-header';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type CtaBannerProps = ProductCategoryHeader & {
  filesMeta?: FilesMeta;
};

const CtaBanner = (props: CtaBannerProps) => {
  const { title, paragraph, image, button, title_2, filesMeta = {} } = props;

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {(title || title_2) && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                {title_2 ? (
                  <>
                    <span className="light">{title}</span> {title_2}
                  </>
                ) : (
                  title
                )}
              </h1>
            )}
          </div>

          {paragraph && (
            <div
              className={cn(Styles.description, 'editorDescription')}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            ></div>
          )}
          {button?.text && (
            <ButtonCustom btnText={button?.text} href={button?.url} />
          )}
        </div>
      </div>
      <div className={Styles.bannerImageContainer}>
        <div className={Styles.bannerImageWrapper} style={zoomStyle}>
          <Image
            src={getDirectusAssetURI(image ?? '') || ''}
            alt={getAltProperty(filesMeta, image ?? '', 'CTA banner')}
            fill
            className={Styles.bannerImage}
          />
        </div>
      </div>
    </div>
  );
};

export default CtaBanner;
