'use client';
import { Divider } from 'antd';
import cn from 'classnames';
import React, { useState } from 'react';

import FormSuccessMessage from '@/app/_components/form-success-message';
import OfferCard from '@/app/_components/offer-card/OfferCard';
import { Form as FormType } from '@/app/_types/components/form';
import { HeaderComponent } from '@/app/_types/components/header-component';
import { Product } from '@/app/_types/components/product';

import ContactForm from '../../contact-us/ContactForm';

import styles from './MyOffers.module.scss';

type OfferProps = {
  scrollTopSuccess: () => void;
  isFormSubmitted: boolean;
  finalProducts: Product[] | null;
  resultTitle: string;
  resultDescription: string;
  mainCategory: string;
  header: HeaderComponent;
  form?: FormType | null;
};

const MyOffers = ({
  scrollTopSuccess,
  isFormSubmitted,
  finalProducts,
  resultTitle,
  resultDescription,
  mainCategory,
  header,
  form,
}: OfferProps) => {
  const [formSuccess, setFormSuccess] = useState(false);
  const handleFormSuccess = () => {
    setFormSuccess(true);
    scrollTopSuccess();
  };

  return (
    <div className="findSolutionPageSection">
      <div className={cn('headingElement', styles.headingWrapper)}>
        <h3 className="subTitle">For {mainCategory}</h3>
        <h1 className={'titleXl normal'}>
          {header &&
            header.dynamic_titles?.map((title) => title.option).join(' ')}
        </h1>
      </div>
      <div className={cn('headingElement', styles.container)}>
        <div className={styles.offers}>
          <div className={styles.offerText}>
            <h4 className="titleLg">{resultTitle}</h4>
            <p className="smallSubTitle normal">{resultDescription}</p>
          </div>
          <div className={styles.offerList}>
            {finalProducts &&
              finalProducts.map((offer, index) => {
                const formattedIndex =
                  index < 9 ? `0${index + 1}` : `${index + 1}`;

                return (
                  <React.Fragment key={index}>
                    {offer.id && (
                      <OfferCard {...offer} productIndex={formattedIndex} />
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <Divider className={styles.quickLinkDivider} />
        <div
          className={cn(styles.formSection, {
            [styles.formSubmitted]: isFormSubmitted,
          })}
        >
          {!formSuccess && form && (
            <ContactForm
              {...form}
              noGrid={true}
              handleFormSuccess={handleFormSuccess}
            />
          )}
          {formSuccess && (
            <FormSuccessMessage
              submittedImage={form?.submitted_image}
              submittedParagraph={form?.submitted_paragraph}
              submittedTitle={form?.submitted_title}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOffers;
