'use client';

import { GoogleMap, Libraries, useLoadScript } from '@react-google-maps/api';
import React, { useCallback } from 'react';
import {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import Styles from './LocationMap.module.scss';
import PlacesAutoComplete from './PlacesAutoComplete';

type LocationMapProps = {
  initialValue: string;
  onChange: (value: string) => void;
};

const mapContainerStyle = {
  width: '100%',
  height: '486px',
};

const defaultCenter = { lat: -26.1438, lng: 28.0248 };
const libraries: Libraries = ["places"];

const LocationMap = ({
  initialValue,
  onChange,
}: LocationMapProps) => {
  const [center, setCenter] = React.useState(defaultCenter);
  const [zoom, setZoom] = React.useState(10);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY),
    libraries,
  });

  const handlePlaceSelect = useCallback(
    async (address: string) => {
      onChange(address);

      try {
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);

        setCenter({ lat, lng });
        setZoom(14);
      } catch (error) {
        console.error('Error fetching location details:', error);
      }
    },
    [onChange],
  );

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  return (
    <div className={Styles.locationMapWrapper}>
      <div className={Styles.searchContainer}>
        { isLoaded && <PlacesAutoComplete initialValue={initialValue} onSelect={handlePlaceSelect}/>}
      </div>

      <GoogleMap
        zoom={zoom}
        center={center}
        mapContainerStyle={mapContainerStyle}
      />
    </div>
  );
};

export default LocationMap;
