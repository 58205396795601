import { AutoComplete, Button, Input } from 'antd';
import Image from 'next/image';
import React from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';


import SearchIcon from '@/app/_icons/search.svg';

import Styles from './LocationMap.module.scss';

type Props = {
  initialValue: string;
  onSelect: (value: string) => void;
};

const PlacesAutoComplete = (props: Props) => {
  const { initialValue, onSelect } = props;

  const {
    suggestions: { data: suggestionsData },
    setValue,
  } = usePlacesAutocomplete({debounce: 400});

  const suggestionOptions = suggestionsData.map(({ description }) => ({
    value: description,
    label: description,
  }));

  const handleSelect = (address: string) => {
    setValue(address);
    onSelect(address);
  };

  return (
    <AutoComplete
      className={Styles.searchBox}
      options={suggestionOptions}
      onSelect={handleSelect}
      onChange={(value) => setValue(value)}
      defaultValue={initialValue}
    >
      <Input.Search
        placeholder="Enter a location."
        enterButton={
          <Button className={Styles.searchBtn}>
            <Image src={SearchIcon} alt="Search" height={14} width={14} />
          </Button>
        }
      />
    </AutoComplete>
  );
}

export default PlacesAutoComplete;
